import logo from "./Clown.png";
import "./App.css";


function App() {
  var url = window.location.href
  var urlList = url.split("/")
  var phrase = urlList[urlList.length - 1].replaceAll("+", " ")
  if (phrase === "") {
    return <meta http-equiv="refresh" content="0; URL='https://popi.marcorealacci.me'" />
  }
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2>{phrase}</h2>
      </header>
    </div>
  );
}

export default App;
